<template>
  <div>
    <div class="box one server">
      <h2 class="bold">{{ $t("618") }}</h2>
      <div class="flexL">
        <div>
          <p class="bold">{{ $t("619") }}</p>
          <select>
            <option value="30day">{{ $t("620") }}</option>
            <option value="60day">{{ $t("621") }}</option>
            <option value="90day">{{ $t("622") }}</option>
            <option value="180day">{{ $t("623") }}</option>
          </select>
        </div>
        <div>
          <p class="bold">{{ $t("624") }}</p>
          <select>
            <option value="30day">{{ $t("620") }}</option>
            <option value="60day">{{ $t("621") }}</option>
            <option value="90day">{{ $t("622") }}</option>
            <option value="180day">{{ $t("623") }}</option>
          </select>
        </div>
      </div>

      <button class="point large">{{ $t("625") }}</button>
    </div>
    <div class="box one">
      <h2 class="bold">스레드 상태</h2>
      <table>
        <tr>
          <th>스레드</th>
          <th>{{ $t("629") }}</th>
          <th>{{ $t("630") }}</th>
          <th>작업파일</th>
          <th>명령</th>
        </tr>
        <tr v-for="(data, i) in jobList" :key="i">
          <td>Tra0001</td>
          <td>On</td>
          <td>Active</td>
          <td>VOD124578311</td>
          <td>
            <button class="tablepoint">{{ $t("634") }}</button>
          </td>
        </tr>
      </table>
      <div class="pagination">
        <el-pagination
          small
          layout="prev, pager, next"
          :total="total"
          :page-size="size"
          @current-change="handleCurrentChange"
          :current-page="currentPage + 1"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { fetchJobList } from "@/api/server";
export default {
  props: ["serverId"],
  data() {
    return {
      moment: moment,
      currentPage: 0,
      size: 10,
      total: 0,
      jobList: [],
    };
  },
  mounted() {
    this.getJobList();
  },
  methods: {
    handleCurrentChange(val) {
      this.currentPage = val - 1;
    },
    getJobList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        keyword: "",
        contentsId: "",
        serverId: this.serverId,
        // requestDateRange: {
        //   from: moment().format("YYYY-MM-DD"),
        //   to: moment().format("YYYY-MM-DD"),
        // },
        statuses: "",
      };
      fetchJobList(params).then((res) => {
        this.total = res.data.data.total;
        this.jobList = res.data.data.content;
      });
    },
  },
};
</script>
